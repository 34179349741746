<template>
  <v-flex>
    <v-card-text>
      <v-form ref="createGroupForm">
        <v-layout xs12 sm6 justify-content-start d-flex column>
          <v-text-field
            class="mt-1"
            v-model="inputName"
            outline
            :rules="[validators.required]"
            label="Group Name"
            :disabled="!allowCreateOrEdit"
          />
          <v-select 
            class="mt-1"
            align-center
            v-model="inputAmperage"
            outline
            :items="amperageOptions"
            label="Breaker Amperage (A)"
            :rules="[validators.required]"
            :disabled="!allowCreateOrEdit"
          />
        </v-layout>
      </v-form>
    </v-card-text>
    <v-divider class="ma-auto" />
    <v-card-actions class="pa-3 flex flex-wrap">
      <v-btn 
        color="gray" 
        flat="flat" 
        @click="$emit('back')"
      >
        BACK
      </v-btn> 
      <v-spacer />
      <v-btn 
        color="green" 
        flat="flat" 
        :disabled="!groupInputValid || !allowCreateOrEdit"
        @click="submit"
      >
        NEXT
      </v-btn>
    </v-card-actions>
  </v-flex>
</template>

<script>
import { OwnerProxyHelpers, validators } from '@/library/helpers'
export default {
  name: "LoadBalancingCreateGroupForm",
  props: {
    name: {
      type: String | null,
      required: true
    },
    amperage: {
      type: Number | null,
      required: true
    }
  },
  computed: {
    validators: () => validators,
    groupInputValid() { 
      const default_breaker_amperage = 20; 
      return !!this.inputName && (this.inputAmperage >= default_breaker_amperage);
    },
    inputName: {
      get() { return this.name },
      set(v) { return this.$emit("input:name", v.trim()) }
    },
    inputAmperage: {
      get() { return this.amperage },
      set(v) { return this.$emit("input:amperage", v) }
    },
    allowCreateOrEdit() {
      return OwnerProxyHelpers.isHaveAccessToFeature("DEVICE_LOAD_BALANCING_CREATE") || OwnerProxyHelpers.isHaveAccessToFeature("DEVICE_LOAD_BALANCING_EDIT")
    },
    amperageOptions() {
      const options = [];
      for (let i = 20; i <= 200; i += 5) {
        options.push(i);
      }
      return options;
    }
  },
  methods: {
    submit() {
      if (this.$refs.createGroupForm.validate()) {
        this.$emit("submit", { name: this.inputName, amperage: this.inputAmperage });
      }
    }
  }
}
</script>