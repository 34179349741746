var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('v-card-text',[_c('v-layout',{attrs:{"xs12":"","justify-content-start":"","d-flex":"","column":""}},[_c('div',[_c('h3',{staticClass:"mt-2"},[_vm._v("Device ID :")]),_c('span',[_vm._v(_vm._s(_vm.current.identifier))]),_c('h3',{staticClass:"mt-2"},[_vm._v("Device Description :")]),_c('span',[_vm._v(_vm._s(_vm.current.description))])]),_c('h3',{staticClass:"mt-3 mb-1"},[_vm._v("Load Balance Values:")]),_c('v-form',{ref:"configureDeviceForm"},[_c('v-text-field',{key:("minimum_" + _vm.inputMaximum),staticClass:"mt-1",attrs:{"outline":"","type":"number","rules":[
            _vm.validators.required,
            _vm.validators.number,
            _vm.validators.integerNumber,
            function (v) { return _vm.validators.maxAmperage(v, 'Minimum Amperage'); },
            function (v) { return _vm.validators.maxOrSameNumber(v || 0, _vm.inputMaximum || 0, 'Maximum Amperage'); }
          ],"disabled":_vm.deviceIsNotOcpp,"label":"Minimum Amperage","hint":_vm.amperageHint,"persistent-hint":""},model:{value:(_vm.inputMinimum),callback:function ($$v) {_vm.inputMinimum=_vm._n($$v)},expression:"inputMinimum"}}),_c('v-text-field',{key:("maximum_" + _vm.inputMinimum),staticClass:"mt-1",attrs:{"outline":"","type":"number","rules":[
            _vm.validators.required,
            _vm.validators.number,
            _vm.validators.integerNumber,
            function (v) { return _vm.validators.maxAmperage(v, 'Maximum Amperage'); },
            function (v) { return _vm.validators.minOrSameNumber(v || 0, _vm.inputMinimum || 0, 'Minimum Amperage'); }
          ],"disabled":_vm.deviceIsNotOcpp,"label":"Maximum Amperage","hint":_vm.amperageHint,"persistent-hint":""},model:{value:(_vm.inputMaximum),callback:function ($$v) {_vm.inputMaximum=_vm._n($$v)},expression:"inputMaximum"}}),_c('v-text-field',{key:_vm.index,staticClass:"mt-1",attrs:{"outline":"","type":"number","rules":[_vm.validators.required, _vm.validators.number, _vm.validators.integerNumber, function (v) { return _vm.validators.maxAmperage(v, 'Always On Amperage'); }],"disabled":_vm.deviceIsNotOcpp,"label":"Always on Amperage","hint":_vm.amperageHint,"persistent-hint":""},model:{value:(_vm.inputAlwaysOn),callback:function ($$v) {_vm.inputAlwaysOn=$$v},expression:"inputAlwaysOn"}}),(!_vm.deviceIsNotOcpp)?_c('div',{staticClass:"mt-2"},[_c('span',[_vm._v("Advanced Settings")]),_c('v-btn',{staticClass:"ma-0",attrs:{"flat":"","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();_vm.showAdvanced = !_vm.showAdvanced}}},[(!_vm.showAdvanced)?_c('v-icon',{staticClass:"up-arrow-icon"},[_vm._v("arrow_left")]):_c('v-icon',{staticClass:"down-arrow-icon",staticStyle:{"color":"green"}},[_vm._v("arrow_drop_down")])],1)],1):_vm._e(),(_vm.showAdvanced && !_vm.deviceIsNotOcpp)?_c('div',{staticClass:"mb-0",staticStyle:{"display":"flex","align-items":"baseline"}},[_c('v-text-field',{staticClass:"mt-1 mb-1",staticStyle:{"font-size":"14px"},attrs:{"outline":"","type":"number","rules":[_vm.validators.minNumber, _vm.validators.integerNumber],"label":"Ratio"},model:{value:(_vm.inputRatio),callback:function ($$v) {_vm.inputRatio=_vm._n($$v)},expression:"inputRatio"}}),_c('v-tooltip',{attrs:{"bottom":"","offset-y":-10},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"tooltip-icon pull-right ml-2"},on),[_vm._v("\n                info\n              ")])]}}],null,false,3222830761)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tooltipContent)}})])],1):_vm._e(),(!_vm.deviceIsNotOcpp)?_c('h5',{staticClass:"mt-1"},[_vm._v("\n          Proportion of current assigned to this device with respect to the other devices in the group\n        ")]):_vm._e()],1)],1)],1),_c('v-card-actions',{staticClass:"pa-3 flex flex-wrap"},[_c('v-btn',{attrs:{"color":"gray","flat":"flat"},on:{"click":_vm.back}},[_vm._v("\n      BACK\n    ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","flat":"flat"},on:{"click":_vm.next}},[_vm._v("\n      NEXT\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }