<template>
  <v-flex xs12>
    <h3 v-if="!error" class="text-xs-center">Device is offline or not powered on!</h3>
    <p class="mt-3 pb-4 mb-4 flex text-xs-justify" style="border-bottom:1px solid rgba(0,0,0,0.1); font-weight: 400">
      To proceed with offline registration, please enter the device’s secret token. This token is provided and packaged with your device. If you have lost this token, please
      contact Plugzio support using the “Chat With Us” tab on the left navigation bar.
    </p>
    <v-text-field
      v-model="plugInputs.offlineRegistrationToken"
      label="Enter Device's Secret Token"
      outline
      autocomplete="false"
      required
      ref="regPlugToken"
      :rules="[rules.required]"
      :error="!!error"
      :disabled="!!loading"
      persistent-hint
      hint="Click the camera icon to launch your camera to scan the QR code"
      @input="$emit('plugInputted', plugInputs)"
    >
      <template v-slot:append>
        <v-fade-transition leave-absolute>
          <qr-scanner @captured="captured" />
        </v-fade-transition>
      </template>
    </v-text-field>
    <p class="my-4 mx-2 flex text-xs-justify">
      <strong>Default Power Settings:</strong>
      Always On
      <br /><br />
      <span>
        Device will automatically start delivering power when turned on. To change this setting, configure the device on the "Device Management" page once the device is powered on
        and connected to the internet. 
      </span>
    </p>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex";
import validators from "./../../mixins/validators";
import QrScanner from "./../modals/QrScanner";
import plugModes from "./../../mixins/plugModes";

export default {
  mixins: [validators, plugModes],
  props: {
    // accessPlans: {
    //   type: Array,
    //   default: () => {
    //     return [];
    //   },
    // },
    useAccessPlans: {
      default: true,
      type: Boolean,
    },
    plug: {
      type: Object,
      default: () => {
        return {
          walletId: null,
          offlineRegistrationToken: null,
          plugIdentifier: null,
          description: null,
          hideDevice: false
        };
      },
      required: true,
    },
    mode: {
      type: Object,
      default: () => {
        return {
          ispublic: null,
          plan_id: null,
        };
      },
      required: true,
    },
    error: {
      default: null,
    },
  },
  components: {
    QrScanner,
  },
  data() {
    return {
      plugInputs: { ...this.plug },
      modeInputs: { ...this.mode },
      actionSub: null,
    };
  },
  computed: {
    ...mapGetters(["accessPlans"]),
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    captured(evt) {
      if (!evt || evt == "clear") {
        this.plugInputs.offlineRegistrationToken = null;
        return;
      }
      let is_json = false;
      let is_error = true;
      try {
        JSON.parse(evt);
        is_json = true;
      } catch (error) {
        is_json = false;
      }
      if (is_json) {
        const qr = JSON.parse(evt);
        this.plugInputs.offlineRegistrationToken = qr.secretToken;
        this.$emit("plugInputted", this.plugInputs);
        this.$nextTick(() => {
          this.$emit("qrScanned");
        });
        is_error = false;
      }
      if (is_error) this.$emit("error", "Non Plugzio QR Code detected");
    },
  },
  mounted() {
    this.actionSub = this.$store.subscribeAction((action) => {
      if (action.type === "editOfflinePlug") {
        this.$nextTick(() => {
          this.plugInputs = { ...this.plug };
          this.modeInputs = { ...this.mode };
        });
      }
    });
  },
  beforeDestroy() {
    this.actionSub();
  },
};
</script>
