<template>
  <v-dialog v-model="registerPlugModal" max-width="500">
    <v-card id="register-plug-dialog">
      <v-card-title>
        <h3>{{ isEdit ? "Edit" : "Register" }} Device</h3>
        <v-icon
          v-if="!isEdit"
          class="tooltip-icon ml-2"
          v-tooltip="{
            content: `Register new devices to this account.<br>All registered devices can be seen on this page.`,
            trigger: 'click hover',
          }"
          >info</v-icon
        >
      </v-card-title>
      <v-alert id="register-device-error" :value="error" type="error" dismissible>{{ error }}</v-alert>
      <v-card-text>
        <v-form ref="plugForm" @submit="!isEdit ? registerPlug() : updatePlug()" v-model="valid">
          <MergedRegisterPlugOnlineInput
            :key="registerPlugModal"
            :edit="isEdit"
            :plug="plugValue"
            :mode="modeValue"
            :autopayment="autopayment"
            :error="error"
            :show-autopayment="showAutopayment"
            @plugInputted="(v) => $emit('plugInputted', v)"
            @modeInputted="(v) => $emit('modeInputted', v)"
            @autopaymentInputted="(v) => $emit('autopaymentInputted', v)"
            @error="onErrorInput"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <template>
          <v-btn v-if="isEdit" color="gray" flat="flat" @click="registerPlugModal = false">Cancel</v-btn>
          <v-btn v-else color="gray" flat="flat" @click="goCancel()">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="!isEdit" color="green darken-1" flat="flat" :loading="loading" @click="registerPlug()">SUBMIT</v-btn>
          <v-btn v-else color="green darken-1" flat="flat" :loading="loading" @click="updatePlug()">SUBMIT</v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import validators from "./../../mixins/validators";
import plugModes from "./../../mixins/plugModes";
import QrScanner from "./../modals/QrScanner";
import MergedRegisterPlugOnlineInput from "./MergedRegisterPlugOnlineInput";
import Api from "@library/apis/Api";
import { cloneDeep as _cloneDeep, pick as _pick } from "lodash-es";
import { ApiHelpers } from '@/library/helpers';

export default {
  mixins: [validators, plugModes],
  props: { plug: Object, mode: Object, autopayment: Object, showAutopayment: { type: Boolean, default: true } },
  components: {
    QrScanner,
    MergedRegisterPlugOnlineInput,
  },
  data() {
    return {
      registerPlugModal: false,
      error: null,
      valid: true,
      actionSub: null,
    };
  },
  computed: {
    ...mapState({ 
      preferences: state => state.Global.preferences || {},
      role: state => state.Auth.role,
      managerview: (state) => state.Admin.managerview,
      activeAdminView: (state) => state.Admin.activeView,
    }),
    ...mapGetters("Admin", { viewAsOwner: "viewAsOwner" }),
    loading() {
      return this.$store.state.loading;
    },
    edit() {
      return this.$store.state.plugEdit;
    },
    isEdit() {
      return !!this.edit && !!this.edit.id;
    },
    plugValue: {
      get() {
        return this.plug;
      },
      set(v) {
        this.$emit("plugInputted", v);
      },
    },
    modeValue: {
      get() {
        return this.mode;
      },
      set(v) {
        this.$emit("modeInputted", v);
      },
    },
    autopaymentValue: {
      get() {
        return this.autopayment;
      },
      set(v) {
        this.$emit("autopaymentInputted", v);
      },
    },
  },
  watch: {
    edit() {
      // if (this.isEdit) this.plug = JSON.parse(JSON.stringify(this.edit));
      
      if (this.isEdit) this.resetInputState(this.edit);
    },
    registerPlugModal() {
      if (!this.registerPlugModal) this.error = false;
    },
  },
  methods: {
    goCancel() {
      this.registerPlugModal = false;
    },
    registerPlug() {
      if (this.$refs.plugForm.validate()) {
        this.$store.dispatch("loading", true);
        this.error = null;
        let data = _cloneDeep(this.plugValue);
        data.offlineRegistrationToken = null;
        if (!!this.managerview) data.ownerUsername = this.managerview.owner_username
        Api.plugRegister(this.role, data)
          .then(() => {
            this.setAccessAndAutopayment();
          })
          .catch((error) => {
            console.error("register error", error);
            this.error = error && error.response ? error.response.data : false;
            this.$store.dispatch("loading", false);
            if (!this.error) return;
            if (["device not reachable"].includes(this.error.toLowerCase())) {
              this.registerPlugModal = false;
              this.$store.dispatch("editOfflinePlug", null);
            }
          });
      }
    },
    updatePlug() {
      if (this.$refs.plugForm.validate()) {
        this.$store.dispatch("loading", true);
        this.error = null;
        const setDescriptionRequest = Api.plugSetDescription(
          this.role,
          { plugIdentifier: this.plugValue.plugIdentifier, description: this.plugValue.description }
        );
        let requests = [setDescriptionRequest];
        if (this.showAutopayment) {
          const payload = { plugIdentifier: this.plugValue.plugIdentifier, walletId: this.autopaymentValue.walletId }
          if (!!this.managerview) payload.ownerUsername = this.managerview.owner_username
          const autopaymentRequest = Api.plugSetPaymentWallet(this.role, payload);
          requests.push(autopaymentRequest);
        }

        Promise.all(requests)
          .then(([accessResponse, autopaymentRequest]) => {
            this.$emit("load");
            this.$store.dispatch("snackbar", {
              message: "Plug Updated",
            });
            
            const preferences = this.managerview?.preferences || this.preferences || {}
            let hiddenDevices = preferences.hiddenDevices || []; 
            if (!this.plugValue.hideDevice) hiddenDevices = hiddenDevices.filter(v => v !== this.plugValue.plugIdentifier)
            else if (!hiddenDevices.includes(this.plugValue.plugIdentifier)) hiddenDevices.push(this.plugValue.plugIdentifier)
          
            const updatedPreferences = {
              ...preferences,
              settings: {
                darkTheme: preferences.settings?.darkTheme || false
              },
              hiddenDevices
            };
            Api.setPrefs(
              ApiHelpers.getApiPrefix(this.role, this.activeAdminView),
              {
                ...ApiHelpers.getUsernamePayloadOfViewAs(this.activeAdminView, this.viewAsOwner),
                prefs: updatedPreferences
              }
            ).then(() => { 
              if (this.role === "admin") {
                this.$store.dispatch("Admin/getPreferencesForView");
                return
              }

              this.$store.dispatch("Global/initializePreferences");
            })
            this.registerPlugModal = false;
          }) 
          .catch((error) => {
            this.error = error && error.response ? error.response.data : false;
            this.$store.dispatch("loading", false);
          });
      }
    },
    setAccessAndAutopayment() {
      this.error = null;
      const requests = [];

      const data = {
        plugIdentifier: this.plugValue.plugIdentifier,
      }
      if (this.modeValue.ispublic) data.planId = this.modeValue.plan_id;
      if (!!this.managerview) data.ownerUsername = this.managerview.owner_username
      requests.push(Api.plugSetAccess(this.role, this.modeValue.ispublic ? "public" : "private", data))

      if (this.showAutopayment) {
        const payload = { plugIdentifier: this.plugValue.plugIdentifier, walletId: this.autopaymentValue.walletId }
        if (!!this.managerview) payload.ownerUsername = this.managerview.owner_username
        requests.push(Api.plugSetPaymentWallet(this.role, payload))
      }

      Promise.all(requests)
        .then(([]) => {
          this.$emit("load");
          this.$store.dispatch("countData");
          this.$store.dispatch("loading", false);
          this.$store.dispatch("snackbar", "Plug Registered");
          this.resetInputState();
          // this.resetInputState(true);
          this.registerPlugModal = false;
          this.$emit("registrationComplete", { plugIdentifier: this.plugValue.plugIdentifier, offline: false });
        })

        .catch((error) => {
          console.error("set access and autopayment error", error);
          this.error = error && error.response ? error.response.data : false;
          this.$store.dispatch("loading", false);
        });
    },
    // resetInputState(reset) {
    resetInputState(plug = null) {
      this.error = null;
      console.log("reset", plug);
      if (plug) {
        this.plugValue = {
          walletId: plug.walletId,
          plugIdentifier: plug.identifier,
          description: plug.description,
          offlineRegistrationToken: null,
          hideDevice: plug.hideDevice || false
        };

        this.modeValue = {
          ispublic: plug.identifier,
          plan_id: plug.plan_id,
        };

        this.autopaymentValue = {
          disabled: !plug.payment_wallet_id,
          walletId: plug.payment_wallet_id,
        };
        // this.$refs.plugForm.reset();
      }
    },
    onErrorInput(value) {
      this.error = value;
    },
  },
  mounted() {
    this.actionSub = this.$store.subscribeAction((action) => {
      if (action.type === "editPlug") {
        this.$nextTick(() => {
          this.registerPlugModal = true;
          if (!action.payload) {
            this.resetInputState();
          }
        });
      }
    });
  },
  beforeDestroy() {
    this.actionSub();
  },
};
</script>
