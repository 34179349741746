<template>
  <v-flex>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12>
          <v-layout row class="mt-0 mb-0">
            <v-flex sm4 xs5>
              <RegexTextfield
                v-model="search"
                :enable-regex="enableRegexSearch"
                @regexToggled="(v) => (enableRegexSearch = v)"
                :disabled="$store.state.loading"
                prepend-icon="search"
              />
            </v-flex>
            <v-flex sm8 xs7 class="text-xs-right pt-3 pr-2" v-if="$store.state.loading">
              <v-progress-circular indeterminate color="orange" :size="24"></v-progress-circular>
            </v-flex>
            <v-flex sm8 xs7 class="text-xs-right page-toolbar pt-3" v-else>
              <v-menu offset-y>
                <v-btn slot="activator" value="status" small flat class="page-toolbar-btn">
                  <span class="hidden-sm-and-down">
                    Status: &nbsp;
                    <strong>{{ filter.state.label }}</strong>
                  </span>
                  <v-icon class="hidden-md-and-up" :class="{ 'success--text': filter.state.property === 1, 'warning--text': filter.state.property === 0 }">power</v-icon>
                </v-btn>
                <v-list hover>
                  <v-list-tile
                    class="pointer"
                    :class="{ 'menu-active': filter.state.property === item.property }"
                    v-for="(item, index) in filter.states"
                    :key="index"
                    @click="filter.state = item"
                  >
                    <small>{{ item.label }}</small>
                  </v-list-tile>
                </v-list>
              </v-menu>
              <v-menu offset-y>
                <v-btn slot="activator" value="status" small flat class="page-toolbar-btn">
                  <span class="hidden-sm-and-down">
                    Power Settings: &nbsp;
                    <strong>{{ filter.status.label }}</strong>
                  </span>
                  <v-icon class="hidden-md-and-up">settings</v-icon>
                </v-btn>
                <v-list hover>
                  <v-list-tile
                    class="pointer"
                    :class="{ 'menu-active': filter.status.property === item.property }"
                    v-for="(item, index) in filter.statuses"
                    :key="index"
                    @click="filter.status = item"
                  >
                    <small>{{ item.label }}</small>
                  </v-list-tile>
                </v-list>
              </v-menu>

              <v-menu offset-y>
                <v-btn slot="activator" value="hideDevice" small flat class="page-toolbar-btn">
                  <span class="hidden-sm-and-down">
                    Show Hidden Devices: &nbsp;
                    <strong>{{ filter.showHiddenDevice ? "Yes" : "No" }}</strong>
                  </span>
                  <v-icon class="hidden-md-and-up">hide_source</v-icon>
                </v-btn>
                <v-list hover>
                  <v-list-tile
                    class="pointer"
                    :class="{ 'menu-active': filter.showHiddenDevice === item.property }"
                    v-for="(item, index) in filter.hiddenOptions"
                    :key="index"
                    @click="filter.showHiddenDevice = item.property"
                  >
                    <small>{{ item.label }}</small>
                  </v-list-tile>
                </v-list>
              </v-menu>

              <v-menu offset-y>
                <v-btn slot="activator" value="order" small flat class="page-toolbar-btn">
                  <span class="hidden-sm-and-down">
                    Sort: &nbsp;
                    <strong>{{ filter.sorting.label }}</strong>
                  </span>
                  <v-icon class="hidden-md-and-up">format_list_bulleted</v-icon>
                </v-btn>
                <v-list hover>
                  <v-list-tile
                    class="pointer"
                    :class="{ 'menu-active': filter.sorting.property === item.property }"
                    v-for="(item, index) in sortOptions"
                    :key="index"
                    @click="filter.sorting = item"
                  >
                    <small>{{ item.label }}</small>
                  </v-list-tile>
                </v-list>
              </v-menu>
              <v-btn flat small class="page-toolbar-btn" @click="filter.desc = !filter.desc" :disabled="!filter.sorting.property">
                <v-icon :class="{ 'rotate-icon': !filter.desc }">sort</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <DeviceCard
              v-for="(device, index) in filteredDevices"
              :key="index"
              :identifier="device.identifier"
              grid-class="md4 xs12"
              :card-class="[
                darkTheme ? 'session-tile__dark' : '',
                inputSelectedDevices.includes(device.identifier) ? 'session-tile__selected' : '',
                hiddenDevices.includes(device.identifier) ? 'outlet-card__hidden' : [],
                !!plugIdentifierGroup[device.identifier] && !isAllowClick(device.identifier) && !darkTheme ? 'session-tile__hasgroup' :  
                !!plugIdentifierGroup[device.identifier] && !isAllowClick(device.identifier) && darkTheme ? 'session-tile__hasgroup-dark' : 'cursor-pointer'
              ].join(' ')"
              @clicked="handleClick(device.identifier)"
            >
              <template #append-body>
                <div 
                  v-if="!!plugIdentifierGroup[device.identifier]" 
                  :class="{ 
                    'plug-tile-details-text-dark': darkTheme, 
                    'plug-tile-details-text': !darkTheme 
                  }"
                >
                  Group Name:
                  <span class="black--text">{{ plugIdentifierGroup[device.identifier].name }}</span>
                </div>
              </template>
            </DeviceCard>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-divider class="ma-auto" />
    <v-card-actions class="pa-3 flex flex-wrap">
      <v-btn 
        color="gray" 
        flat="flat" 
        @click="$emit('back')"
      >
        BACK
      </v-btn> 
      <v-spacer />
      <v-btn 
        color="green" 
        flat="flat"
        @click="submit"
      >
        NEXT
      </v-btn>
    </v-card-actions>
  </v-flex>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import RegexTextfield from '@/components/inputs/RegexTextfield.vue'
import DeviceCard from "@/components/devices/Card.vue"
import PlugHelpers from "@/library/helpers/PlugHelpers"

export default {
  name: "LoadBalancingSelectDevicesForm",
  components: {
    RegexTextfield,
    DeviceCard
  }, 
  props: {
    selectedDevices: {
      type: Array,
      default: () => ([])
    },
    name: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      search: "",
      enableRegexSearch: false,
      filter: {
        desc: false,
        state: {
          label: "All",
          property: "all",
        },
        states: [
          {
            label: "All",
            property: "all",
          },
          {
            label: "On",
            property: 1,
          },
          {
            label: "Off",
            property: 0,
          },
        ],
        status: {
          label: "All",
          property: "all",
        },
        statuses: [
          {
            label: "All",
            property: "all",
          },
          {
            label: "Smart",
            property: 2,
          },
          {
            label: "Always On",
            property: 1,
          },
          {
            label: "Always Off",
            property: 0,
          },
        ],
        showHiddenDevice: false,
        hiddenOptions: [
          {
            label: "Yes",
            property: true,
          },
          {
            label: "No", 
            property: false,
          },
        ],
        sorting: {
          label: "Device ID",
          property: "identifier",
        },
        sort: [
          {
            label: "Device ID",
            property: "identifier",
          },
          {
            label: "Description",
            property: "description",
          },
          {
            label: "Power Settings",
            property: "mode",
          },
          {
            label: "Access Settings",
            property: "access_settings",
          },
          {
            label: "Service Tier",
            property: "service_tier_id",
          },
          {
            label: "Serial Number",
            property: "serial_no",
          }
        ],
      },
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      plugs: state => state.plugs,
    }),
    ...mapGetters("Device", { 
      hiddenDevices: "hiddenDevices"
    }),
    ...mapGetters("LoadBalancing", {
      plugIdentifierGroup: "plugIdentifierGroup"
    }),
    inputSelectedDevices: {
      get() { return this.selectedDevices },
      set(v) { return this.$emit("input:selectedDevices", v) }
    },
    darkTheme() {
      return ((this.$store.state.Global.preferences || {}).settings || {}).darkTheme || false
    }, 
    filteredDevices() {
      let plugs = JSON.parse(JSON.stringify(this.plugs)) || [];
      const formatted_filters = {
        state: this.filter.state.property == "all" ? "all" : parseInt(this.filter.state.property),
        status: this.filter.status.property == "all" ? "all" : parseInt(this.filter.status.property),
        sort_by: this.filter.sorting.property,
        sort_direction_desc: this.filter.desc,
        search: this.search,
        enableRegexSearch: this.enableRegexSearch,
      };
      const hiddenDevices = this.hiddenDevices;
      if (!this.filter.showHiddenDevice) { 
        plugs = plugs.filter(plug => !hiddenDevices.includes(plug.identifier));
      }

      return PlugHelpers.filter(plugs, formatted_filters);
    },
    sortOptions() {
      const options = this.filter.sort; 
      const isAdmin = this.role === 'admin';

      return isAdmin ? options : options.filter(item => item.property !== 'service_tier_id' && item.property !== 'serial_no');
    }
  },
  methods: {
    submit() {
      this.$emit("submit")
    },
    isAllowClick(identifier) {
      const editId = this.$route.params.id;
      const plugGroup = this.plugIdentifierGroup[identifier]
      
      return !(!!plugGroup && (!editId || plugGroup.id != editId))
    },
    handleClick(identifier) {
      if (!this.isAllowClick(identifier)) return

      if (this.inputSelectedDevices.includes(identifier)) {
        this.inputSelectedDevices = this.inputSelectedDevices.filter(id => id !== identifier);
      } else {
        this.inputSelectedDevices.push(identifier);
      }
    }
  },
  
}
</script>
<style lang="scss" scoped>
  :not(.session-tile__selected) {
    .plug-tile-details-text {
      &-dark {
        color: white!important;
      }
      &-dark > * {
        color: white !important;
      }
    }
  }
</style>