<template>
  <v-dialog v-model="registerPlugModal" max-width="500">
    <v-card>
      <v-card-title>
        <h3>Register Device</h3>
        <v-icon
          class="tooltip-icon ml-2"
          v-tooltip="{
            content: `Register new devices to this account.<br>All registered devices can be seen on this page.`,
            trigger: 'click hover',
          }"
          >info</v-icon
        >
      </v-card-title>
      <v-alert :value="error" type="error" dismissible>{{ error }}</v-alert>
      <v-card-text>
        <v-form ref="plugForm" @submit="registerPlug()" v-model="valid">
          <MergedRegisterPlugOfflineInput
            :key="registerPlugModal"
            :plug="plug"
            :mode="mode"
            :error="error"
            @plugInputted="onPlugInputted"
            @modeInputted="onModeInputted"
            @error="onErrorInput"
            @qrScanned="registerPlug()"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="gray" flat="flat" @click="goBack()">Back</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" flat="flat" :loading="loading" @click="registerPlug()">SUBMIT</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validators from "./../../mixins/validators";
import plugModes from "./../../mixins/plugModes";
import QrScanner from "./../modals/QrScanner";
import MergedRegisterPlugOfflineInput from "./MergedRegisterPlugOfflineInput";
import Api from "@library/apis/Api";
import { mapState } from 'vuex';

export default {
  mixins: [validators, plugModes],
  props: ["plug", "mode", "autopayment"],
  components: {
    QrScanner,
    MergedRegisterPlugOfflineInput,
  },
  data() {
    return {
      registerPlugModal: false,
      error: null,
      valid: true,
      actionSub: null,
    };
  },
  computed: {
    ...mapState({
      role: state => state.Auth.role,
      managerview: (state) => state.Admin.managerview,
    }),
    loading() {
      return this.$store.state.loading;
    },
    deviceRegistrationSecretToken() {
      return this.$store.state.deviceRegistrationSecretToken;
    },
  },
  watch: {
    error(value) {
      if (value == "Bad offline registration token") this.error = "Invalid offline registration token. Please try again or contact support.";
    },
    registerPlugModal() {
      if (!this.registerPlugModal) {
        this.error = false;
      }
    },
  },
  methods: {
    goBack() {
      if (!this.error) {
        this.registerPlugModal = false;
        this.$store.dispatch("editPlug", null);
      }
      this.resetInputState();
    },
    goExit() {
      this.resetInputState();
      this.registerPlugModal = false;
      this.$emit("resetInput", true);
    },
    registerPlug() {
      if (this.$refs.plugForm.validate()) {
        this.$store.dispatch("loading", true);
        this.error = null;
        let data = JSON.parse(JSON.stringify(this.plug));
        if (!!this.managerview) data.ownerUsername = this.managerview.owner_username;
        Api.plugRegister(this.role, data)
          .then(() => {
            this.setAccessAndAutopayment();
          })
          .catch((error) => {
            console.log("register error", error);
            this.error = error && error.response ? error.response.data : false;
            this.$store.dispatch("loading", false);
          });
      }
    },
    setAccessAndAutopayment() {
      this.error = null;
      const requests = []

      const data = {
        plugIdentifier: this.plug.plugIdentifier,
      }
      if (this.mode.ispublic) data.planId = this.mode.plan_id;
      if (!!this.managerview) data.ownerUsername = this.managerview.owner_username;
      requests.push(Api.plugSetAccess(this.role, this.mode.ispublic ? "public" : "private", data))
      
      const payload = { plugIdentifier: this.plug.plugIdentifier, walletId: this.autopayment.walletId }
      if (!!this.managerview) payload.ownerUsername = this.managerview.owner_username;
      requests.push(Api.plugSetPaymentWallet(this.role, payload))

      Promise.all(requests)
        .then(() => {
          this.$emit("load");
          this.$store.dispatch("countData");
          this.$store.dispatch("loading", false);
          this.$store.dispatch("snackbar", "Plug Registered");
          this.resetInputState();
          this.registerPlugModal = false;
          this.$emit("registrationComplete", { plugIdentifier: this.plug.plugIdentifier, offline: true });
        })

        .catch((error) => {
          this.error = error && error.response ? error.response.data : false;
          this.$store.dispatch("loading", false);
        });
    },
    resetInputState(autosubmit = false, reset = false) {
      this.error = null;
      const plug = { ...this.plug };
      plug.offlineRegistrationToken = this.deviceRegistrationSecretToken;
      this.$emit("plugInputted", plug);
      if (plug.offlineRegistrationToken && autosubmit) {
        this.$nextTick(() => {
          this.registerPlug();
        });
      }
    },
    onErrorInput(value) {
      this.error = value;
    },
    onModeInputted(value) {
      this.$emit("modeInputted", value);
    },
    onPlugInputted(value) {
      this.$emit("plugInputted", value);
    },
  },
  mounted() {
    this.actionSub = this.$store.subscribeAction((action) => {
      if (action.type === "editOfflinePlug") {
        this.$nextTick(() => {
          this.registerPlugModal = true;
          if (!action.payload) {
            this.resetInputState(true);
          }
        });
      }
    });
  },
  beforeDestroy() {
    this.actionSub();
  },
};
</script>
