<template>
  <v-card class="text-xs-left">
    <v-card-title>
      <h3>Load Balancing</h3>
    </v-card-title>
    <v-card-text>
      <v-layout row>
        <v-flex xs12 d-flex flex-column>
          <span class="mb-2">Load balancing your devices allows you to:</span>
          <ul>
            <li>Operate on the same circuit without tripping the breaker.</li>
            <li>Dynamically adjust and calculate in real time the amount of energy your devices are allowed to distribute base on charging demands.</li>
            <li>Maximizes charging efficiency.</li>
          </ul>
          <span class="mt-4">Would you like to load balance your device?</span>
          <slot />
        </v-flex>
      </v-layout>
      
    </v-card-text>
    <v-card-actions class="flex justify-end">
      <v-btn color="darken-1" flat="flat" @click="$emit('do-this-later')">Do This Later</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "DeviceCreationLoadBalancingCard",
}
</script>