<template>
  <v-flex>
    <v-card-text>
      <v-layout xs12 justify-content-start d-flex column>
        <div> 
          <h3 class="mt-2">Device ID :</h3><span>{{ current.identifier }}</span>
          <h3 class="mt-2">Device Description :</h3><span>{{ current.description }}</span>
        </div>
        <h3 class="mt-3 mb-1">Load Balance Values:</h3>
        <v-form ref="configureDeviceForm">
          <v-text-field
            :key="`minimum_${inputMaximum}`"
            class="mt-1"
            v-model.number="inputMinimum"
            outline
            type="number"
            :rules="[
              validators.required,
              validators.number,
              validators.integerNumber,
              v => validators.maxAmperage(v, 'Minimum Amperage'),
              v => validators.maxOrSameNumber(v || 0, inputMaximum || 0, 'Maximum Amperage')
            ]"
            :disabled="deviceIsNotOcpp"
            label="Minimum Amperage"
            :hint="amperageHint"
            persistent-hint
          />
          <v-text-field
            :key="`maximum_${inputMinimum}`"
            class="mt-1"
            v-model.number="inputMaximum"
            outline
            type="number"
            :rules="[
              validators.required,
              validators.number,
              validators.integerNumber,
              v => validators.maxAmperage(v, 'Maximum Amperage'),
              v => validators.minOrSameNumber(v || 0, inputMinimum || 0, 'Minimum Amperage')
            ]"
            :disabled="deviceIsNotOcpp"
            label="Maximum Amperage"
            :hint="amperageHint"
            persistent-hint
          />
          <v-text-field
            :key="index"
            class="mt-1"
            v-model="inputAlwaysOn"
            outline
            type="number"
            :rules="[validators.required, validators.number, validators.integerNumber, v => validators.maxAmperage(v, 'Always On Amperage')]"
            :disabled="deviceIsNotOcpp"
            label="Always on Amperage"
            :hint="amperageHint"
            persistent-hint
          />
          <div
            v-if="!deviceIsNotOcpp"
            class="mt-2"
          > 
            <span>Advanced Settings</span>
            <v-btn flat small icon class="ma-0" @click.stop="showAdvanced = !showAdvanced">
              <v-icon v-if="!showAdvanced" class="up-arrow-icon">arrow_left</v-icon>
              <v-icon v-else class="down-arrow-icon" style="color: green;">arrow_drop_down</v-icon>
            </v-btn>
          </div>
          <div
            v-if="showAdvanced && !deviceIsNotOcpp"
            style="display: flex; align-items: baseline;" class="mb-0"
          >
            <v-text-field
              class="mt-1 mb-1"
              v-model.number="inputRatio"
              outline
              type="number"
              :rules="[validators.minNumber, validators.integerNumber]"
              label="Ratio"
              style="font-size: 14px;"
            />
            <v-tooltip bottom :offset-y="-10">
              <template v-slot:activator="{ on }">
                <v-icon class="tooltip-icon pull-right ml-2" v-on="on">
                  info
                </v-icon>
              </template>
              <span v-html="tooltipContent" />
            </v-tooltip>
          </div>
          <h5 v-if="!deviceIsNotOcpp" class="mt-1">
            Proportion of current assigned to this device with respect to the other devices in the group
          </h5>
        </v-form>
      </v-layout>
    </v-card-text>
    <v-card-actions class="pa-3 flex flex-wrap">
      <v-btn
        color="gray" 
        flat="flat" 
        @click="back" 
      >
        BACK
      </v-btn>
      <v-spacer />
      <v-btn 
        color="green" 
        flat="flat"
        @click="next"
      >
        NEXT
      </v-btn>
    </v-card-actions>
  </v-flex>
</template>

<script>
import { validators } from '@/library/helpers'
import { mapGetters, mapState } from 'vuex';
import DeviceHelpers from "@/library/helpers/DeviceHelpers";
import texts from "../texts";

const MINIMUM_AMPERAGE = 6

export default {
  name: "LoadBalancingConfigureDevicesForm",
  props: {
    devices: {
      type: Array,
      required: true
    },
    minAmperages: { 
      type: Object,
      default: () => ({})
    },
    maxAmperages: { 
      type: Object,
      default: () => ({})
    },
    alwaysOns: { 
      type: Object,
      default: () => ({})
    },
    ratios: { 
      type: Object,
      default: () => ({})
    },
    startFromLast: {
      type: Boolean,
      default: false
    },
    breakerAmperage: {
      type: Number, 
      default: 0 
    }
  },
  data() {
    return { 
      index: -1,
      showAdvanced: false,
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      addPlugToCollective: (state) => state.LoadBalancing.addPlugToCollective,
    }),
    ...mapGetters(["plugs"]),
    validators() {
      const current = this.current
      return {
        ...validators,
        maxAmperage: (value, field) => {
          if (
            !current
            || !current.parsedSerialNo
            || ![
              DeviceHelpers.models.ocpp, DeviceHelpers.models.plugzio_ocpp
            ].includes(current.parsedSerialNo.model)
          ) return true;
          
          const numValue = parseFloat(value);
          const errorText = `The scale for ${field} should fall within the range of ${MINIMUM_AMPERAGE} to ${current.stableAmperage}
            for this specific device and must not exceed 80% of the breaker amperage.`;

          return (numValue >= MINIMUM_AMPERAGE && numValue <= current.stableAmperage) || errorText
        }
      }
    },
    current() {
      const currentDeviceIdentifier = this.devices[this.index];
      const currentDevice = this.plugs.find((plug) => plug.identifier === currentDeviceIdentifier);
      const parsedSerialNo = DeviceHelpers.parseSerialNumber(currentDevice?.serial_no || "") || {};
      const current = parseInt(parsedSerialNo.current)
      const isOcpp = [DeviceHelpers.models.ocpp, DeviceHelpers.models.plugzio_ocpp].includes(parsedSerialNo.model);
      const groupAmperage = (this.breakerAmperage || 0) * DeviceHelpers.STABLE_AMPERAGE_CONVERTER
      const stableAmperage = isOcpp ? Math.min(groupAmperage, current) : groupAmperage;

      let defaultAmperage = null; 
      if (parsedSerialNo.model === DeviceHelpers.models.integrated) {
        defaultAmperage = (current <= 15) ? 12 : 16;
      }
      if ([DeviceHelpers.models.global, DeviceHelpers.models.universal].includes(parsedSerialNo.model)) { 
        defaultAmperage = 16;
      }
      return {
        identifier: currentDevice ? currentDevice.identifier || "" : "",
        description: currentDevice ? currentDevice.description || "" : "",
        serial_no: currentDevice ? currentDevice.serial_no || "" : "",
        parsedSerialNo,
        defaultAmperage,
        stableAmperage,
        minAmperage: isOcpp ? MINIMUM_AMPERAGE : defaultAmperage,
        maxAmperage: isOcpp ? stableAmperage : defaultAmperage,
        alwaysOnAmperage: isOcpp ? MINIMUM_AMPERAGE : defaultAmperage,
        get defaultRatio() {
          return [
            DeviceHelpers.models.global, DeviceHelpers.models.universal,
            DeviceHelpers.models.integrated
          ].includes(parsedSerialNo.model) ? 0 : 1
        }
      }
    },
    inputMinimum: {
      get() { return this.minAmperages[this.current.identifier] },
      set(v) { this.$emit("input:minAmperages", { ...this.minAmperages, [this.current.identifier]: v }) }
    },
    inputMaximum: {
      get() { return this.maxAmperages[this.current.identifier] },
      set(v) { this.$emit("input:maxAmperages", { ...this.maxAmperages, [this.current.identifier]: v }) }
    },
    inputAlwaysOn: {
      get() { return this.alwaysOns[this.current.identifier] },
      set(v) { this.$emit("input:alwaysOns", { ...this.alwaysOns, [this.current.identifier]: v }) }
    },
    inputRatio: {
      get() { return this.ratios[this.current.identifier] },
      set(v) { this.$emit("input:ratios", { ...this.ratios, [this.current.identifier]: v }) }
    },
    tooltipContent: () => texts.configureDeviceTooltip,
    deviceIsNotOcpp() {
      return [
        DeviceHelpers.models.integrated, DeviceHelpers.models.global,
        DeviceHelpers.models.universal
      ].includes(this.current.parsedSerialNo.model)
    },
    loadBalancingListRoute() {
      return this.role === "admin" 
        ? "/admin/managerview-load-balancing"
        : "/owner/load-balancing"
    },
    amperageHint() {
      if ([DeviceHelpers.models.ocpp, DeviceHelpers.models.plugzio_ocpp].includes(this.current.parsedSerialNo.model)) {
        return `Please enter values between ${MINIMUM_AMPERAGE} A and ${this.current.stableAmperage} A for this specific device.`;
      }
    }
  },
  methods: {
    back() {
      if (this.$route.query?.mode === "add-plug-to-collective") {
        this.$router.push(`${this.loadBalancingListRoute}?mode=selection`)
        return
      }

      if (this.index <= 0) {
        this.$emit("back");
        return
      }

      this.index--;
    },
    next() {
      if (!this.$refs.configureDeviceForm.validate()) return;

      this.$emit("input:minAmperages", { ...this.minAmperages, [this.current.identifier]: this.inputMinimum });
      this.$emit("input:maxAmperages", { ...this.maxAmperages, [this.current.identifier]: this.inputMaximum });
      this.$emit("input:alwaysOns", { ...this.alwaysOns, [this.current.identifier]: this.inputAlwaysOn });
      this.$emit("input:ratios", { ...this.ratios, [this.current.identifier]: this.inputRatio });

      if (this.index >= this.devices.length - 1) {
        this.$emit("submit");
        return
      }

      this.index++;
    }
  },
  watch: {
    index() {
      if (isNaN(parseInt(this.inputMinimum))) this.inputMinimum = this.current.minAmperage;
      if (isNaN(parseInt(this.inputMaximum))) this.inputMaximum = this.current.maxAmperage;
      if (isNaN(parseInt(this.inputAlwaysOn))) this.inputAlwaysOn = this.current.alwaysOnAmperage;
      if (isNaN(parseInt(this.inputRatio))) this.inputRatio = this.current.defaultRatio;
    }
  },
  mounted() {
    this.index = 0;
    if (this.startFromLast || (this.$route.query?.mode === "add-plug-to-collective" && this.addPlugToCollective)) {
      this.index = this.devices.length - 1;
    }
  }
}
</script>