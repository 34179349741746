<template>
  <div>
    <v-layout row class="mt-0 mb-0">
      <v-flex sm4 xs5>
        <RegexTextfield
          v-model="search"
          :enable-regex="enableRegexSearch"
          @regexToggled="(v) => (enableRegexSearch = v)"
          :disabled="loading"
          prepend-icon="search"
          class="search-input"
        />
      </v-flex>
      <v-flex sm8 xs7 class="text-xs-right pt-2 pr-2" v-if="loading">
        <v-progress-circular indeterminate color="orange" :size="24"></v-progress-circular>
      </v-flex>
      <v-flex sm8 xs7 class="text-xs-right page-toolbar pt-3" v-else>
        <v-menu offset-y>
          <v-btn slot="activator" value="order" small flat class="page-toolbar-btn">
            <span class="hidden-sm-and-down">
              Sort: &nbsp;
              <strong>{{ filter.sorting.label }}</strong>
            </span>
            <v-icon class="hidden-md-and-up">format_list_bulleted</v-icon>
          </v-btn>
          <v-list hover>
            <v-list-tile
              class="pointer sorting-menu"
              :class="{ 'menu-active': filter.sorting.property === item.property }"
              v-for="(item, index) in filter.sort"
              :key="index"
              @click="filter.sorting = item"
            >
              <small>{{ item.label }}</small>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-btn flat small class="page-toolbar-btn" @click="filter.desc = !filter.desc" :disabled="!filter.sorting.property">
          <v-icon :class="{ 'rotate-icon': !filter.desc }">sort</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout
      column 
      v-for="(collective, i) in filteredCollectives"
      :key="i"
      :class="{ 
        'border-solid-container-dark' : darkTheme, 
        'border-solid-container' : !darkTheme 
      }"
      class="mt-4 mb-0 mr-2 ml-2"
    >
      <v-flex
        nowrap
        d-flex
        align-center
        xs12
        class="text-xs-left page-toolbar py-2" 
        :class="{ 
          'bottom-border-dark': darkTheme, 
          'bottom-border' : !darkTheme
        }"
      >
        <span class="ml-2">
          Group Name: <strong>{{ collective.name }}</strong>
        </span>
        <v-spacer />
        <div class="text-xs-right">
          <span class="mr-2">
            Breaker Amperage: <strong>{{ (collective.amperage / DeviceHelpers.STABLE_AMPERAGE_CONVERTER) + " A" }}</strong>
          </span>

          <v-btn
            v-if="mode === 'selection'"
            small
            color="green"
            class="action-button create-button mx-0 px-3 mb-1 mt-1 mr-2 ml-2"
            :class="{
              'action-button-dark' : darkTheme
            }"
            style="min-width: auto; height: 70%; border-radius: 30px;"
            round
            outline
            @click.stop.prevent="$router.push(`${loadBalancingFormRoute}/${collective.the_collective_id}?mode=add-plug-to-collective`)"
          >
            Select
          </v-btn>
          <template v-else>
            <v-btn
              small
              color="green"
              class="action-button create-button mx-0 px-3 mb-1 mt-1 mr-2 ml-2"
              :class="{
                'action-button-dark' : darkTheme
              }"
              style="min-width: auto; height: 70%; border-radius: 30px;"
              round
              outline
              :disabled="!hasAccess.DELETE"
              @click.stop.prevent="deleteCollective = collective"
            >
              <v-icon size="20" style="margin: 0;" key="icon">delete_forever</v-icon>
            </v-btn>
            <v-btn
              small
              class="action-button create-button mx-0 px-3 mb-1 mt-1 mr-2"
              :class="{
                'action-button-dark' : darkTheme
              }"
              style="min-width: auto; height: 70%; border-radius: 30px;"
              round
              outline
              :disabled="!hasAccess.EDIT"
              @click="$router.push(`${loadBalancingFormRoute}/${collective.the_collective_id}`)"
            >
              <v-icon size="20" style="margin: 0;" key="icon">edit</v-icon>
            </v-btn>
          </template>
        </div>
      </v-flex>
      <v-layout
        class="pa-3"
        wrap
      >
        <div
          v-if="collective.devices.length === 0"
          class="d-flex justify-center align-center w-full"
          style="height: 100px;"
        >
          No Devices
        </div>
        <template v-else>
          <DeviceCard
            v-for="(device, i) in collective.devices"
            :key="i"
            :identifier="device.plug_identifier"
          >
            <template #append-body>
              <div class="plug-tile-details-text">
                Minimum Current:
                <span class="black--text">{{ device.min_amperage + ' A' }}</span>
              </div>
              <div class="plug-tile-details-text">
                Maximum Current:
                <span class="black--text">{{ device.max_amperage + ' A' }}</span>
              </div>
              <div class="plug-tile-details-text">
                Always On Current:
                <span class="black--text">{{ device.default_amperage + ' A' }}</span>
              </div>
              <div class="plug-tile-details-text">
                Ratio:
                <span class="black--text">{{ device.vote }}</span>
              </div>
            </template>
          </DeviceCard>
        </template>
      </v-layout>
    </v-layout>
    <v-dialog 
      v-model="showDeleteConfirmation"
      max-width="450"
    >
      <v-card class="text-center">
        <v-card-title>
          <h3>Delete Load Balancing Group</h3>
        </v-card-title>
        <v-card-text class="text-xs-justify">
          <v-flex class="flex items-center">
            <h3 class="text-xs-center mb-2 warning-sign"> 
              <strong> 
                <span class = "red--text">WARNING</span> 
              </strong> 
            </h3>
            <p class="text-xs-left mb-3 mt-3"> 
              <ul> 
                <li>Deleted group cannot be restored</li>
                <li>Device in the group will no longer be load balanced</li>
              </ul>
            </p>

            <span>Are you sure you want to delete load balance group <strong>{{ (deleteCollective || {}).name || "" }}</strong>?</span> 
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="gray"
            flat="flat"
            @click="showDeleteConfirmation = false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            flat="flat"
            @click="goDelete"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import { cloneDeep } from "lodash-es"
import { DeviceHelpers, OwnerProxyHelpers } from "@/library/helpers"
import Api from "@/library/apis/Api"
import RegexTextfield from "@/components/inputs/RegexTextfield.vue";
import DeviceCard from "@/components/devices/Card.vue";

export default {
  name: "LoadBalancingList",
  components: {
    RegexTextfield,
    DeviceCard
  },
  data() {
    return {
      search: null,
      enableRegexSearch: false,
      filter: {
        desc: false,
        sorting: {
          label: "Group Name",
          property: "name"
        },
        sort: [
          {
            label: "Group Name",
            property: "name",
          },
          {
            label: "Breaker Amperage",
            property: "amperage",
          },
        ],
      },
      deleteCollective: null,
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      loading: (state) => state.loading,
      preferences: state => state.Global.preferences || {},
      addPlugToCollective: state => state.LoadBalancing.addPlugToCollective,
    }),
    ...mapGetters("LoadBalancing", { 
      groupedDevices: "groupedDevices",
    }),
    ...mapGetters("Device", { 
      hiddenDevices: "hiddenDevices"
    }),
    darkTheme() {
      return ((this.$store.state.Global.preferences || {}).settings || {}).darkTheme || false
    },
    showDeleteConfirmation: {
      get() { return this.deleteCollective !== null },
      set(v) { 
        if (!v) this.deleteCollective = null 
      }
    },
    groupedDeviceWithoutHiddenDevices() {
      return this.groupedDevices.map(g => {
        return {
          ...g,
          devices: g.devices.filter(d => !(this.hiddenDevices || []).includes(d.plug_identifier))
        }
      })
    },
    filteredCollectives() {
      const search = new RegExp((this.search || "").toLowerCase(), "");
      const collectives = !this.enableRegexSearch
        ? this.groupedDeviceWithoutHiddenDevices.filter(c => c.filter_string.toLowerCase().includes((this.search || "").toLowerCase()))
        : this.groupedDeviceWithoutHiddenDevices.filter(c => c.filter_string.match(search))

      return collectives.sort((a, b) => {
        let propA = a[this.filter.sorting.property].toString().toLowerCase();
        let propB = b[this.filter.sorting.property].toString().toLowerCase();

        if (this.filter.sorting.property === "amperage") {
          propA = parseInt(propA)
          propB = parseInt(propB)
          return this.filter.desc ? propB - propA : propA - propB
        }

        return this.filter.desc ? propB.localeCompare(propA) : propA.localeCompare(propB)
      })
    },
    DeviceHelpers: () => DeviceHelpers,
    mode() {
      return this.$route.query?.mode || "list"
    },
    loadBalancingFormRoute() {
      return this.role === "admin" 
        ? "/admin/managerview-load-balancing-form"
        : "/owner/load-balancing-form"
    },
    hasAccess() {
      return {
        EDIT: OwnerProxyHelpers.isHaveAccessToFeature("DEVICE_LOAD_BALANCING_EDIT"),
        DELETE: OwnerProxyHelpers.isHaveAccessToFeature("DEVICE_LOAD_BALANCING_DELETE"),
      }
    }
  },
  methods: {
    async goDelete() {
      try {
        this.$store.dispatch("loading", true)
        const collective = cloneDeep(this.deleteCollective)
        this.showDeleteConfirmation = false
        await Api.loadBalancingCollectiveDelete(this.role, { collectiveId: collective.the_collective_id  })
        
        this.$store.dispatch("snackbar", {
          message: `Successfully remove load balancing group ${collective.name}`,
        });
      } catch (error) {
        this.$store.dispatch("snackbar", {
          message: `Failed to remove load balancing group ${collective.name}`,
        });
      } finally {
        this.$store.dispatch("loading", false)
        this.$store.dispatch("LoadBalancing/get");
      }
    },
  },
  mounted() {
    if (this.mode === "selection" && !this.addPlugToCollective) {
      this.$router.push(this.$route.path)
    }
  }
}
</script>

<style scoped lang="scss">
.border-solid-container {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &-dark {
    background-color: #333333;
    border: 1px solid none; 
    border-radius: 8px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
}
.bottom-border { 
  &-dark { 
    border-bottom: 1px solid slategray;
    border-color: whitesmoke;
    background-color: #0e660e;
    color: whitesmoke
  }

  border-bottom: 1px solid #747171; 
  border-color: #747171;
  background-color: #0d920d;
  color: whitesmoke
}
.search-input {
  width: 100%;
  max-width: 400px; 
  margin-right: 10px;
}
.sorting-menu {
  display: flex;
  align-items: center;
}
.action-button {
  &-dark {
    color: #bbb5b5 !important;
  }
  color: #fcfffc !important;
}
</style>