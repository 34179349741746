export default {
  ratio:
    `Keep ratio as one if you are not sure how to use this parameter.<br>
      Examples:<br>
      <ul>
        <li>Group has a maximum amperage of 48A with a ratio of 1:2:3 for devices A/B/C respectively</li>
        <li>All devices in the group have a min and max current of 8A and 48A respectively</li>
        <li>If 1 device is on, it gets 48 A</li>
        <li>If 2 devices are on:
          <ul>
            <li>Devices A/B will get 16/32A respectively</li>
            <li>Devices B/C will get 19/28A respectively</li>
            <li>Devices C/A will get 12/36A respectively</li>
          </ul>
        </li>
        <li>If all 3 devices are on, devices A/B/C will get 8/16/24A respectively</li>
      </ul>
    `,
  configureDeviceTooltip: ` 
    Keep ratio as one if you are not sure on how to use this parameter.<br/><br/>
      Example:<br/><br/>
        <ul>
          <li>Group has a maximum amperage of 48A with ratio of 1:2:3 for devices A/B/C respectively</li>
          <li>All devices in the group have a min and max current of 6A and 48A respectively</li>
          <li>If 1 device is on, it gets 48A</li>
          <li>If 2 devices are on:</li>
          <ul>
            <li>Devices A/B will get 18/30A respectively</li>
            <li>Devices B/C will get 20/27A respectively</li>
            <li>Devices C/A will get 15/33A respectively</li>
          </ul>
          <li>If all 3 devices are on, devices A/B/C will get 12/18/24A respectively</li>
        </ul>
  `,
}