<template>
  <v-flex>
    <v-card-text>
      <v-layout 
        xs12 
        justify-content-start 
        d-flex 
        column 
      >
        <h3>Group Information</h3>
        <span class="mt-2">
          Name:
          <strong> {{ inputs.name }} </strong>
        </span>
        <span class="mt-2">
          Breaker Amperage:
          <strong> {{ inputs.amperage + ' A' }}</strong>
        </span>
        <template>
          <h3 class="mt-4">Devices</h3>
          <div>
            <div v-if="selectedDevices.length === 0">
              <h3 class="text-xs-center my-3 py-3">No Devices</h3>
            </div>
            <div v-else>
              <div 
                v-for="(identifier, index) in selectedDevices" 
                :key="index" 
                class="mt-3 mb-2" 
                style="margin-top: -10px !important;"
              >
                <p>Device: <strong>{{ identifier || "" }}</strong></p>
                <p class="ml-3 summary-informations">Minimum Amperage: <strong>{{ inputs.minAmperages[identifier] + ' A' || "" }}</strong></p>
                <p class="ml-3 summary-informations">Maximum Amperage: <strong>{{ inputs.maxAmperages[identifier] + ' A' || "" }}</strong></p>
                <p class="ml-3 summary-informations">Always on Amperage: <strong>{{ inputs.alwaysOns[identifier] + ' A' || "" }}</strong></p>
                <p v-if="inputs.ratios[identifier] !== null" class="ml-3 summary-informations">Ratio: <strong>{{ inputs.ratios[identifier] || "0" }}</strong></p>
              </div>
            </div>
          </div>
        </template>
      </v-layout>
    </v-card-text>
    <v-divider class="ma-auto" />
    <v-card-actions class="pa-3 flex flex-wrap">
      <v-btn 
        color="gray" 
        flat="flat" 
        @click="$emit('back')"
      >
        BACK
      </v-btn> 
      <v-spacer />
      <v-btn 
        color="green" 
        flat="flat"
        @click="$emit('submit')"
      >
        CONFIRM
      </v-btn>
    </v-card-actions>
  </v-flex>
</template>

<script>
export default {
  name: "LoadBalancingSummaryForm", 
  props: {
    selectedDevices: {
      type: Array,
      default: () => ([])
    },
    inputs: {
      type: Object,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
.summary-informations {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 8px;
}
</style>