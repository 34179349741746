<template>
  <div>
    <v-btn 
      small 
      dark 
      color="green" 
      class="plugzio-button create-button mx-0 px-3 mb-1"
      style="min-width: auto; height: 30%; border-radius: 30px;"
      round 
      outline 
      :disabled="!allowRegister"
      @click="openModal()"
    >
      <v-icon size="20" class="ma-0 font-weight-bold" key="icon">add</v-icon>
      <span 
        class="ml-1" 
        key="description"
        :class="{ 
          'hidden-md-and-down': !alwaysShowButtonText,  
        }"
      >
        REGISTER DEVICE
      </span>
    </v-btn>

    <MergedRegisterPlugOnline
      :plug="plug"
      :mode="modeChange"
      :autopayment="autopayment"
      @load="$emit('load')"
      @plugInputted="onPlugInputted"
      @modeInputted="onModeInputted"
      @autopaymentInputted="onAutopaymentInputted"
      @resetInput="onInputReset"
      @registrationComplete="onRegistrationComplete"
    />
    <MergedRegisterPlugOffline
      :plug="plug"
      :mode="modeChange"
      :autopayment="autopayment"
      @load="$emit('load')"
      @plugInputted="onPlugInputted"
      @modeInputted="onModeInputted"
      @autopaymentInputted="onAutopaymentInputted"
      @resetInput="onInputReset"
      @registrationComplete="onRegistrationComplete"
    />

    <v-dialog v-model="showCompleteRegistrationDialog" max-width="500">
      <v-card>
        <v-card-title>
          <h3>Register Device</h3>
          <v-icon
            class="tooltip-icon ml-2"
            v-tooltip="{
              content: `Register new devices to this account.<br>All registered devices can be seen on this page.`,
              trigger: 'click hover',
            }"
          >
            info
          </v-icon>
        </v-card-title>
        <v-card-text>
          <h3 id="register-device-success" class="text-xs-center text-plugzio">
            Device Successfully Registered!
          </h3>
          <v-layout row class="mt-4 mb-0" justify-center>
            <v-flex xs9 v-if="!loading">
              <div class="text-xs-left">
                <span>Device Information:</span>
              </div>
              <div class="text-xs-left">
                <ul>
                  <li>
                    <span>Device ID: </span>
                    <strong>{{ registeredPlugIdentifier }}</strong>
                  </li>
                  <li>
                    <span>Power Setting: </span>
                    <strong>{{ isOfflineRegistration ? "Always On" : "Smart" }}</strong>
                  </li>
                  <li>
                    <span>Device Type: </span>
                    <strong>{{ registeredPlug ? registeredPlug.communication_type : "" }}</strong>
                  </li>
                  <li>
                    <span>Service Subscription Duration: </span>
                    <strong>{{ registeredPlugDataplan ? registeredPlugDataplan.calculated_timespan : "" }}</strong>
                  </li>
                </ul>
              </div>
            </v-flex>
            <v-flex v-else xs12 class="text-xs-center">
              <v-progress-circular class="loading-circle" size="40" indeterminate color="orange" />
            </v-flex>
          </v-layout>
          <v-layout row class="mt-4 mb-0" justify-center>
            <v-flex xs12>
              <p class="text-xs-justify">
                The subscription plan of your device can now be configured on the
                <strong class="text-plugzio cursor-pointer" @click="$router.push('/owner/service-subscription')">Subscription Service</strong>
                page
              </p>
            </v-flex>
          </v-layout>
          
          <v-layout v-if="isOfflineRegistration" row class="mt-2 mb-0" justify-center>
            <!-- // comented to hide Dialog  -->
            <!-- <v-flex xs9>
              <p class="mt-3 pb-4 mb-4 flex text-xs-justify" style="color:rgba(0,0,0,0.8)">
                Offline device will start in "Always On" mode. If the device is powered on and within close proximity, you can configure the device's power settings through
                Bluetooth.
              </p> -->
              <!-- <v-btn class="plugzio-button mb-2 mt-4 wifi-setup-button" block outline round @click="$router.push('/owner/ble-configuration')">
                Device Setup via BLE
              </v-btn> -->
            <!-- </v-flex> -->
          </v-layout>
          
        </v-card-text>
        <v-card-actions class="flex justify-end">
          <v-btn color="darken-1" flat="flat" @click="openLoadBalancingDialog">NEXT</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showLoadBalancingDialog" max-width="500">
      <DeviceCreationLoadBalancing
        @do-this-later="showLoadBalancingDialog = false"
      >
        <v-btn
          class="plugzio-button mt-4 text-transform-none"
          block
          outline
          round
          @click="$router.push(`${loadBalancingRoute}-form`)"
        >
          Create a New Load Balancing Group
        </v-btn>

        <v-btn
          class="plugzio-button text-transform-none"
          block
          outline
          round
          @click="$router.push(`${loadBalancingRoute}?mode=selection`)"
        >
          Select an Existing Load Balancing Group
        </v-btn>
      </DeviceCreationLoadBalancing>
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/library/apis/Api";
import MergedRegisterPlugOnline from "./MergedRegisterPlugOnline.vue";
import MergedRegisterPlugOffline from "./MergedRegisterPlugOffline.vue";
import { mapState } from "vuex";
import { OwnerProxyHelpers } from "@/library/helpers";
import DeviceCreationLoadBalancing from '../loadBalancing/DeviceCreationLoadBalancing.vue';
export default {
  props: {
    alwaysShowButtonText: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MergedRegisterPlugOnline,
    MergedRegisterPlugOffline,
    DeviceCreationLoadBalancing
  },
  data() {
    return {
      plug: {
        walletId: null,
        plugIdentifier: null,
        description: null,
        offlineRegistrationToken: null,
        hideDevice: false
      },
      modeChange: {
        ispublic: null,
        plan_id: null,
      },
      autopayment: {
        disabled: false,
        walletId: null,
      },
      registeredPlugIdentifier: null,
      registeredPlugDataplan: null,
      showCompleteRegistrationDialog: false,
      isOfflineRegistration: false,
      showLoadBalancingDialog: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      plugs: (state) => state.plugs,
      role: (state) => state.Auth.role,
    }),
    registeredPlug() {
      const selectedPlug = this.plugs.find((plug) => plug.identifier == this.registeredPlugIdentifier);
      if (!selectedPlug) return null;
      return selectedPlug;
    },
    loadBalancingRoute() {
      return this.role === "admin" ? "/admin/managerview-load-balancing" : "/owner/load-balancing"
    },
    allowRegister() {
      return OwnerProxyHelpers.isHaveAccessToFeature("DEVICE_REGISTER");
    }
  },
  watch: {
    showCompleteRegistrationDialog: function(value) {
      if (!value) {
        this.isOfflineRegistration = false;
        this.registeredPlugIdentifier = null;
      }
    },
  },
  methods: {
    openModal() {
      // this.$store.dispatch("loading", true);
      // this.showCompleteRegistrationDialog = true;
      this.onInputReset(true);
      this.$store.dispatch("setDeviceRegistrationSecretToken", null);
      this.$store.dispatch("editPlug", null);
      // this.$store.dispatch("editOfflinePlug", null);
      // if (this.selectedRegistrationMethod == "online") this.$store.dispatch("editPlug", null);
      // if (this.selectedRegistrationMethod == "offline") this.$store.dispatch("editOfflinePlug", null);
    },
    onPlugInputted(value) {
      this.plug = value;
    },
    onModeInputted(value) {
      this.modeChange = value;
    },
    onAutopaymentInputted(value) {
      this.autopayment = value;
    },
    onInputReset(value) {
      if (value) {
        this.plug = {
          walletId: null,
          plugIdentifier: null,
          description: null,
          offlineRegistrationToken: null,
          hideDevice: false
        };
        this.modeChange = {
          ispublic: null,
          plan_id: null,
        };
        this.autopayment = {
          disabled: false,
          walletId: null,
        };
      }
    },
    onRegistrationComplete(registration) {
      if (!registration) return;
      this.showCompleteRegistrationDialog = true;
      this.isOfflineRegistration = registration.offline ? registration.offline : false;
      this.registeredPlugIdentifier = registration.plugIdentifier ? registration.plugIdentifier : null;
      this.$store.dispatch("loading", true);
      this.onInputReset(true);
      Api.plugDataplanConfig(this.role, { plugIdentifier: this.registeredPlugIdentifier })
        .then((dataplan) => {
          dataplan.calculated_timespan = Math.floor(dataplan.timespan / 3600 / 24) + " days";
          this.registeredPlugDataplan = dataplan;
        })
        .finally(() => this.$store.dispatch("loading", false));
    },
    openLoadBalancingDialog() {
      this.$store.commit("LoadBalancing/setState", { addPlugToCollective: this.registeredPlugIdentifier });
      this.showCompleteRegistrationDialog = false;
      this.showLoadBalancingDialog = true;
    }
  }
};
</script>
