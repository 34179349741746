<template>
  <div style="display: flex; align-items: baseline;" class="mb-0">
    <h3 class="text-xs-left">
      {{  
        menu === null ? (isEdit ? 'Edit Group' : 'Create Group')
        : menu === 'select-devices' ? (isEdit ? 'Edit Group > Select Devices' : 'Create Group > Select Devices')
        : menu === 'configure-devices' ? (isEdit ? 'Edit Group > Select Devices > Configure Device' : 'Create Group > Select Devices > Configure Device')
        : menu === 'summary-confirmation' ? 'Summary'
        : '' 
      }}
    </h3> 

    <template>
      <v-tooltip v-if="menu === 'select-devices'" bottom :offset-y="-10">
        <template v-slot:activator="{ on }">
          <v-icon 
            class="tooltip-icon text-xs-right pull-right" 
            style="position: absolute; right: 25px;" 
            v-on="on"
          >
            info
          </v-icon>
        </template>
        <span>
          <ul>
            <li>Select devices by clicking the device tile</li>
            <li>Devices already in a group cannot be selected</li>
            <li>Hidden devices are not shown on the list</li>
          </ul>
        </span>
      </v-tooltip>
    </template>
  </div>
</template>

<script>
export default {
  name: "LoadBalancingFormTitle",
  props: {
    menu: {
      type: String,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  }
}
</script>