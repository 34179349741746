<template>
  <v-flex xs12>
    <v-text-field
      id="register-dialog-inputs-identifier"
      v-if="!edit"
      v-model="plugInputs.plugIdentifier"
      label="Device ID"
      outline
      autocomplete="false"
      required
      ref="regPlugId"
      :rules="[rules.required]"
      :error="!!error"
      :disabled="!!loading"
      persistent-hint
      hint="Characters 'A' to 'K', '0' to '9' and '.' only"
      autofocus
      @input="onPlugIdentifierInput()"
    >
      <template v-slot:append>
        <v-fade-transition leave-absolute>
          <qr-scanner @captured="captured" />
        </v-fade-transition>
      </template>
    </v-text-field>

    <v-text-field
      id="register-dialog-inputs-description"
      v-model="plugInputs.description"
      label="Description"
      outline
      autocomplete="false"
      required
      :error="!!error"
      :disabled="!!loading"
      persistent-hint
      hint="e.g. Guest Stall #1"
      @input="$emit('plugInputted', plugInputs)"
    ></v-text-field>
    <br />
    <div>
      <v-layout v-if= "edit" align-center>
        <h3 class="d-inline-block">Hide Device: </h3>
        <VTooltipHandler
          classes="ml-2 mb-0"
          icon="info"
          :text="`
          Hiding a device will cause devices to not show up on visually on the device management page. 
          This is useful for devices that have been deprecated. 
          Hiding a device does not disable a device or affect its configuration.
          `" 
        />
      </v-layout>
      <v-select 
        v-if="edit"
        align-center
        v-model="plugInputs.hideDevice"
        class="mt-0 mb-0"
        :items="[{ value: false, text: 'Disabled' }, { value: true, text: 'Enabled' }]"
        item-text="text"
        item-value="value"
        outline
        label="Enable/Disable Device"
        :error="!!error"
        :disabled="loading"
        :rules="[(v) => [true, false].includes(v) || 'This field is required']"
        @change="$emit('plugInputted', plugInputs)"
      />
    </div>
    <v-select
      v-if="!edit"
      v-model="modeInputs.ispublic"
      :items="subModes"
      item-text="name"
      item-value="code"
      label="Access"
      outline
      required
      :rules="[rules.subMode]"
      :error="modeError ? true : false"
      :disabled="!!loading"
      :hint="accessDescription"
      persistent-hint
      @input="$emit('modeInputted', modeInputs)"
    >
      <template slot="selection" slot-scope="data">
        <template>
          <v-list-tile-content>
            <v-list-tile-title v-html="data.item.name"></v-list-tile-title>
          </v-list-tile-content>
        </template>
      </template>
      <template slot="item" slot-scope="data">
        <template>
          <v-list-tile-content>
            <v-list-tile-title v-html="data.item.name"></v-list-tile-title>
            <v-list-tile-sub-title v-html="data.item.description"></v-list-tile-sub-title>
          </v-list-tile-content>
        </template> </template
    ></v-select>
    <v-autocomplete
      v-if="modeInputs.ispublic && !edit && useAccessPlans"
      v-model="modeInputs.plan_id"
      :items="accessPlans"
      item-text="name"
      item-value="id"
      label="Payment Plan"
      no-data-text="No payment plans available"
      outline
      required
      :rules="[rules.required]"
      :error="modeError || planError ? true : false"
      :disabled="!!loading"
      hint="Default payment plan for users with no access."
      persistent-hint
      @input="$emit('modeInputted', modeInputs)"
    />
    <p v-if="!edit" class="mt-3 mr-3 ml-2 text-xs-left">
      Default Power Settings:
      <strong>Smart</strong>
      <br />
      <span class="modal-description">{{ plugModes[0].description }}</span>
    </p>
    <div v-if="showAutopayment">
      <v-layout align-center>
        <h3 class="d-inline-block">Service Subscription Plan:</h3>
        <VTooltipHandler
          classes="ml-2 mb-0"
          icon="info"
          :text="`
            Plugzio devices typically come with 1 year of cloud dashboard access.
            <br/>After a year, you have the option of subscribing to a monthly to keep your device connected to the cloud.
            <br/>Subscription settings can always be changed in the under the &quot;Service Subscription&quot; page.
          `" 
        />
      </v-layout>
      <v-autocomplete
        v-if="autopaymentInputs.disabled"
        :items="specificWallets"
        item-text="name"
        item-value="id"
        label="Wallet"
        outline
        autocomplete="false"
        disabled
        class="mt-0 wallet-picker"
      ></v-autocomplete>
      <v-autocomplete
        v-else
        v-model="autopaymentInputs.walletId"
        :items="specificWallets"
        item-text="name"
        item-value="id"
        label="Wallet"
        outline
        autocomplete="false"
        required
        :rules="[rules.required]"
        :error="!!error"
        :disabled="loading || autopaymentInputs.disabled"
        class="mt-3 wallet-picker"
        @input="$emit('autopaymentInputted', autopaymentInputs)"
      ></v-autocomplete>
      <v-layout align-center>
        <p class="wallet-hint mb-0">Payments in USD or CAD currencies only</p>
      </v-layout>
      <v-divider class="mt-4 mb-0"></v-divider>
      <div class="wallet-dialog-separator">
        OR
      </div>

      <v-checkbox class="mt-4" v-model="autopaymentInputs.disabled" required color="success" hide-details>
        <template v-slot:label>
          Disable Auto-payment
        </template>
      </v-checkbox>
      </div>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex";
import validators from "./../../mixins/validators";
import plugModes from "./../../mixins/plugModes";
import QrScanner from "./../modals/QrScanner";
import sort from "./../../mixins/sort";
import VTooltipHandler from "@/components/VTooltipHandler.vue";

export default {
  mixins: [validators, plugModes, sort],
  props: {
    useAccessPlans: {
      default: true,
      type: Boolean,
    },
    plug: {
      type: Object,
      default: () => {
        return {
          walletId: null,
          offlineRegistrationToken: null,
          plugIdentifier: null,
          description: null,
          hideDevice: false
        };
      },
      required: true,
    },
    mode: {
      type: Object,
      default: () => {
        return {
          ispublic: null,
          plan_id: null,
        };
      },
      required: true,
    },
    autopayment: {
      type: Object,
      default: () => {
        return {
          disabled: false,
          walletId: null,
        };
      },
      required: true,
    },
    edit: {
      default: false,
      type: Boolean,
    },
    error: {
      default: null,
    },
    showAutopayment: { type: Boolean, default: true },
  },
  components: {
    QrScanner,
    VTooltipHandler
  },
  data() {
    return {
      plugInputs: { ...this.plug },
      modeInputs: { ...this.mode },
      actionSub: null,
      autopaymentInputs: { ...this.autopayment },
    };
  },
  computed: {
    ...mapGetters(["accessPlans"]),
    // availableWallets() {
    //   return this.wallets.filter((wallet) => ["CAD", "USD"].includes(wallet.currency));
    // },
    loading() {
      return this.$store.state.loading;
    },
    wallets() {
      return this.$store.state.wallets.sort(this.compareValues("description")) || [];
    },
    ...mapGetters("Wallet", {
      specificWallets: "specificWallets",
    }),
  },
  watch: {
    "autopaymentInputs.disabled": function(value) {
      if (value) this.autopaymentInputs.walletId = null;
      this.$emit("autopaymentInputted", this.autopaymentInputs);
    },
  },
  methods: {
    captured(evt) {
      if (!evt || evt == "clear") {
        this.plugInputs.plugIdentifier = null;
        return;
      }
      let is_error = true;
      let is_json = false;
      try {
        JSON.parse(evt);
        is_json = true;
      } catch (error) {
        is_json = false;
      }
      if (evt.match(/my.plugz.io\/activate-plug\?external_id=/)) {
        let code = evt.split("=");
        this.plugInputs.plugIdentifier = code[1];
        this.onPlugIdentifierInput();
        is_error = false;
      }
      if (is_json) {
        const qr = JSON.parse(evt);
        this.plugInputs.plugIdentifier = qr.deviceId;
        this.onPlugIdentifierInput();
        this.$store.dispatch("setDeviceRegistrationSecretToken", qr.secretToken);
        is_error = false;
      }
      if (is_error) this.$emit("error", "Non Plugzio QR Code detected");
    },
    onPlugIdentifierInput() {
      if (this.plugInputs.plugIdentifier && !this.plugInputs.plugIdentifier.includes("aryawifi")) {
        this.$nextTick(() => {
          if (!this.plugInputs.plugIdentifier.match(/-/)) {
            this.plugInputs.plugIdentifier = this.plugInputs.plugIdentifier.toUpperCase().replace(/[^0-9a-k.]/gi, "");
          }
        });
      }
      this.$nextTick(() => {
        this.$emit("plugInputted", { ...this.plugInputs });
      });
    },
  },
  mounted() {
    this.actionSub = this.$store.subscribeAction((action) => {
      if (action.type === "editPlug") {
        this.$nextTick(() => {
          this.plugInputs = { ...this.plug };
          this.modeInputs = { ...this.mode };
          this.autopaymentInputs = { ...this.autopayment };
        });
      }
    });
  },
  beforeDestroy() {
    this.actionSub();
  },
};
</script>

<style lang="scss">
.wallet-dialog-separator {
  width: 50px;
  margin: -10px auto;
  background: #fff;
  text-align: center;
}
.wallet-hint {
  color: rgba(0, 0, 0, 0.64);
  line-height: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  font-size: 12px;
  min-height: 12px;
  min-width: 1px;
  position: relative;
  padding-left: 8px;
  margin-bottom: 12px;
}
.wallet-picker {
  & .v-text-field__details {
    display: none;
  }
  &.error--text .v-text-field__details {
    display: block;
  }
}
</style>
